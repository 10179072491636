// @flow

import * as React from 'react';
import { Link } from 'gatsby';
import Bio from './bio';

import { rhythm, scale } from '../utils/typography';

declare var __PATH_PREFIX__: string;

const rootPath = `${__PATH_PREFIX__}/`;

const Title = ({ title }: { title: string }) => (
  <Link
    style={{
      boxShadow: `none`,
      textDecoration: `none`,
      color: `inherit`,
    }}
    to="/"
  >
    {title}
  </Link>
);

const Header = ({ isRoot, title }: { isRoot: boolean, title: string }) => {
  if (isRoot) {
    return (
      <h1
        style={{
          ...scale(1.5),
          marginBottom: rhythm(1.5),
          marginTop: 0,
          fontFamily: "'IBM Plex Mono', monospace",
        }}
      >
        {Title({ title })}
      </h1>
    );
  }

  return (
    <h3
      style={{
        marginTop: 0,
        fontFamily: "'IBM Plex Mono', monospace",
      }}
    >
      {Title({ title })}
    </h3>
  );
};

function Layout({
  location,
  title,
  children,
}: {
  location: Location,
  title: string,
  children: React.Node,
}) {
  const isRoot = location.pathname === rootPath;

  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(24),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <header>{Header({ isRoot, title })}</header>
      <main>{children}</main>
      {!isRoot ? (
        <footer>
          <Bio />
        </footer>
      ) : null}
    </div>
  );
}

export default Layout;
